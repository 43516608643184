

const TOKEN_KEY = "xplur-web-admin"
export const USER_KEY = "kobokist-web-admin"

export function getActiveStore() {
    return sessionStorage.getItem('user') ? sessionStorage : localStorage;
}

export function getUserToken() {
    return getActiveStore().getItem(TOKEN_KEY)
}
export function isUserLoggedIn() {
    var isLoggedIn = getActiveStore().getItem(TOKEN_KEY)
    if(isLoggedIn){
        window.location.href = "/adminindex";
    }
}
export function logOutUser() {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(USER_KEY);
    setTimeout(() => {
        window.location.href = "/";
    }, 2000);
}
export function loginUser(payload) {
    localStorage.setItem(TOKEN_KEY, payload?.authToken);
    localStorage.setItem(USER_KEY, JSON.stringify(payload));

    window.location.href = "/adminindex";
}