import "./App.css";
import "./assets/css/dashboard.css";
import "./assets/css/pricing-plan.css";
import Index from "./screens/front/home/index";
import "bootstrap/dist/css/bootstrap.min.css";
// import TicketList from "./screens/front/ticketList";
import PaymentConfirmed from "./screens/front/paymentConfirmed";
import Reservations from "./screens/front/reservations";
import AdminIndex from "./screens/admin/index";
import EventManagement from "./screens/admin/eventManagement";
import UserManagement from "./screens/admin/userManagement";
import PaymentManagement from "./screens/admin/paymentManagement";
import ServiceCategoryManagement from "./screens/admin/serviceCategoryManagement";
import AdminLogin from "./screens/front/login";
import FishShopManagement from "./screens/admin/fish-shop-management";
import PropertyManagement from "./screens/admin/property-management";
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Kycverificationrequests from "./screens/admin/kycverificationrequests";
function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path={"/"} element={<Index />} exact /> */}
        {/* <Route path={"/ticketList"} element={<TicketList />} exact />
        <Route path={"/reservationsList"} element={<Reservations />} exact /> */}
        <Route
          path={"/paymentConfirmed"}
          element={<PaymentConfirmed />}
          exact
        />
        <Route path={"/"} element={<AdminLogin />} exact />
        <Route path={"/adminindex"} element={<AdminIndex />} exact />
        <Route path={"/"} />
        <Route path={"/eventManagement"} element={<EventManagement />} exact />
        <Route path={"/serviceRequests"} element={<UserManagement />} exact />
        <Route path={"/kycverificationrequests"} element={<Kycverificationrequests />} exact />
        <Route path={"/service-category-management"} element={<ServiceCategoryManagement />} exact />
        <Route path={"/payment-management"} element={<PaymentManagement />} exact />
        <Route path={"/fishshop-management"} element={<FishShopManagement />} exact />
        <Route path={"/property-management"} element={<PropertyManagement />} exact />
      </Routes>
    </Router>
  );
}

export default App;
