

export function nairaFormat(num, dp = 0, inKobo = true) {
    return currencyFormat(num, '₦', dp)
}
export function currencyFormat(base_denomination, symbol = '₦', dp = 0) {
    // base_denomination = parseFloat(base_denomination)
    return symbol + (base_denomination).toFixed(dp)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

