import React, { useEffect, useState } from 'react';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { Link } from 'react-router-dom';
import { loadSlim } from "@tsparticles/slim";
import { Badge, Card, Dropdown, Space, Drawer, Image } from 'antd';
import { useLocation } from "react-router-dom";
import Endpoint from '../../utils/endpoint';
import { nairaFormat } from '../../utils/helpers';
import { DownOutlined } from '@ant-design/icons';
import logoLloydant from "../../assets/images/xplurlogo.png"
import succ from "../../assets/images/suc.gif"
import $ from "jquery"


function PaymentConfirmed() {
    const location = useLocation();
    const [init, setInit] = useState(false);
    const [ticketArr, setTicketArr] = useState([]);
    const trxref = new URLSearchParams(location.search).get("trxref");
    const [open, setOpen] = useState(false);
    const [payloadArr, setpayloadArr] = useState([]);
    const [totalAmount, settotalAmount] = useState(0);
    const [emailAddress, setemailAddress] = useState("");
    const [confirmemailAddress, setconfirmemailAddress] = useState("");
    const [fullname, setfullname] = useState("");
    const [btnDisabled, setbtnDisabled] = useState(true);
    const [isConfirmed, setisConfirmed] = useState(false);

    const triggerpaymentConfirmation = () => {
        Endpoint.paymentConfirmation(trxref)
            .then((res) => {
                console.log(res?.data)
                if(res?.data){
                    setisConfirmed(true)
                    urlSanitize()
                    setTimeout(() => {
                        $('#preloader').delay(450).fadeOut('slow');
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log(err, "Err")
                urlSanitize()
            })
    }
    const urlSanitize = () => {
        let stateObj = { id: "100" };
        window.history.replaceState(stateObj, "x 2", "/paymentConfirmed");
    }
    useEffect(() => {
        triggerpaymentConfirmation()
    }, []);

    return (
        <>
            <div id="preloader">
                <div id="status">
                    <img alt='loader' src={logoLloydant} className="pt-5 lloyd-logo" />
                </div>
            </div>
            {/* <Button type="primary" onClick={showDrawer}>
                Open
            </Button> */}
        
            {/* <div className=''> */}
          
          
           {isConfirmed && 
           
                <center className='container' style={{ marginTop: "5em" }}>
                    <img alt='loader' src={succ} style={{ width: "166px", marginLeft: "auto", marginRight: "auto" }} />
                    <p className='outfit_head mt-5' style={{ fontSize: "25px" }}>Payment Successful!</p>
                    <p className='outfit_text'>Congratulations! Your payment was successful!
                        <br />
                        An email with details of ticket(s) purchased would be forwarded to you shortly...
                    </p>
                    <Link to={"/"} className='btn btn-primary outfit_text'>Continue</Link>
                </center>
           }
            
        </>
    );
}

export default PaymentConfirmed;
