import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, Dropdown, Space, Drawer, Image, Spin } from 'antd';
import { useLocation } from "react-router-dom";
import Endpoint from '../../utils/endpoint';
import { isUserLoggedIn, loginUser } from '../../utils/auth';
import $ from "jquery"
import { LoadingOutlined } from '@ant-design/icons'
import { ToastContainer, toast } from 'react-toastify';

require("../../assets/css/adminlogin.css")



function AdminLogin() {
    const location = useLocation();
    const [init, setInit] = useState(false);
    const [ticketArr, setTicketArr] = useState([]);
    const [loading, setloading] = useState(false);
    const [inputValues, setInputValues] = useState({
        checkbox: false,
        text: '',
    });

    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(value)
        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleLogin = () => {
        console.log(inputValues?.username, "cc")
        if (!inputValues?.username) {
            return;
        }
        setloading(true)
        const payload = {
            "username": inputValues?.username,
            "password": inputValues?.password,
            "fcmToken": ""
        }
        console.log(payload)
        Endpoint.signIn(payload)
            .then((res) => {
                console.log(res?.data)
                // return
                // if (res?.data?.authToken != null) {
                    loginUser(res?.data);
                    setloading(false)
                // }
            })
            .catch((err) => {
                console.log(err)
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>{err?.data?.message}</p>);

                setloading(false)
            })
    }
    useEffect(() => {
        isUserLoggedIn()
    }, []);

    return (
        <>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <div className='login-admin-body'>
                <div className="container-admin-login">
                    <div className="card-admin-login shadow">
                        <h2 className='text-center outfit-500 mb-3' style={{ color:"#4a4b4e"}}>Admin Login</h2>
                        <p className='text-center outfit-400' style={{marginTop:"-13px", fontSize:"14px"}}>Kobokist Admin Portal</p>
                        <form className='admin-login-form'>
                            <input className='admin-login-input outfit-400' onChange={(e) => handleInput(e)} type="text" id="username" name="username" placeholder="Username" required />
                            <input className='admin-login-input outfit-400' type="password" onChange={(e) => handleInput(e)} id="password" name="password" placeholder="Password" required />
                            <button disabled={loading} className='admin-login-button outfit-400' type="button" onClick={() => handleLogin()}>
                                {loading && <Spin style={{
                                    color: "#fff",
                                }} indicator={<LoadingOutlined spin />} />}
                                {loading ? " Signing you in..." : "Login"}</button>
                        </form>
                    </div>
                </div>
           </div>
           

        </>
    );
}

export default AdminLogin;
