import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  AuditOutlined,
  DashboardFilled,
  DashboardOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import xplurlogo from "../../assets/images/xplurwhitemain.png";
import { enquireScreen } from 'enquire-js';
import { logOutUser } from "../../utils/auth";

const { Header, Sider, Content } = Layout;

const Sidenav = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setactiveKey] = useState("0");
  const [isMobile, setIsMobile] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const handleSetActiveKey = () => {
    var arraySplit = document.URL.split("/");
    let activeCompo = arraySplit[arraySplit?.length - 1];
    if (activeCompo.includes("adminindex")) {
      console.log(activeCompo);
      setactiveKey("1");
      setTimeout(() => {
        console.log(activeKey);
      }, 2000);
    } else if (activeCompo.includes("manageapplications")) {
      console.log(activeCompo);
      setactiveKey("3_2");
      setTimeout(() => {
        console.log(activeKey);
      }, 2000);
    }
  };
  const handleRedirects = (data) => {
    window.location.href = data;
  };
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(b)
      b && setCollapsed(true)
    });
    // setTimeout(() => {
    handleSetActiveKey();
    // }, 2000);
  }, []);
  return (
    <div>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minHeight: "100vh", background: '#1a409c' }}
        >
          <center style={{ padding: "5px" }} className="mb-3 mt-2">
            {/* <img
              src={xplurlogo}
              style={{
                height: "50px",
                width: "auto",
              }}
            /> */}
            <h3 className="outfit-600" style={{ color: "#fa8c60" }}>{collapsed ? "KL" : "Kobokist"}</h3>
          </center>
          <Menu
            // theme="none"
            theme="none"
            mode="inline"
            defaultSelectedKeys={[activeKey]}
            items={[
              {
                key: "1",
                icon: <DashboardOutlined />,
                label: "Dashboard",
                onClick: () => handleRedirects("/adminindex"),
              },
              {
                key: "2",
                icon: <UploadOutlined />,
                label: "Service Categories",
                onClick: () => handleRedirects("/service-category-management"),
              },
              // {
              //     key: '2',
              //     icon: <VideoCameraOutlined />,
              //     label: 'Manage Users',
              // },
              // {
              //   key: "3",
              //   icon: <AuditOutlined />,
              //   label: "Event Category",
              //   onClick: () => handleRedirects(""),
              // },
              {
                key: "3",
                icon: <AuditOutlined />,
                label: "Service Requests",
                onClick: () => handleRedirects("/serviceRequests"),
              },
              {
                key: "4",
                icon: <AuditOutlined />,
                label: "KYC Verifications",
                onClick: () => handleRedirects("/kycverificationrequests"),
              },
              {
                key: "5",
                icon: <AuditOutlined />,
                label: "Payment Management",
                onClick: () => handleRedirects("/payment-management"),
              },
              {
                key: "6",
                icon: <AuditOutlined />,
                label: "Fish Shop Management",
                onClick: () => handleRedirects("/fishshop-management"),
              },
              {
                key: "7",
                icon: <AuditOutlined />,
                label: "Property Management",
                onClick: () => handleRedirects("/property-management"),
              },
              {
                key: "8",
                icon: <UserOutlined />,
                label: "Log Out",
                onClick: () => logOutUser(),
              },
            ]}
          />
          <h2 className="side-nav-blend-text outfit-500">KoboKist</h2>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: "#fff",
            }}
          >
            <div style={{display:"flex"}}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <p className="outfit-600">Hi Admin!</p>
            </div>
            
           
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: "#fff",
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Sidenav;
