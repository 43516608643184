import React from "react";
import FrontHeader from "./header";

function FrontLayout({ children }) {
  return (
    <div>
      <FrontHeader />
      <div style={styles.content}>{children}</div>
    </div>
  );
}
const styles = {
  navbar: {
    backgroundColor: "#333",
    padding: "10px",
    color: "white",
    textAlign: "center",
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 1000,
  },
  content: {
    // margin: '0px',
    // width: '100%',
    // marginTop: '60px',
    // padding: '20px',
  },
};
export default FrontLayout;
