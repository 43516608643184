import axios from "axios";
// import { notify, objectToHTTPQuery } from "./helpers";
import { getUserToken, logOutUser, rememberRoute } from "./auth";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  token: localStorage.getItem("token"),
};

let fileHeaders = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
  token: localStorage.getItem("token"),
};

//export const baseContentURL = "http://10.211.55.4/";
export const baseContentURL = "https://api.kobokist.thehebrewsshelters.com/";

const Endpoint = {
  init: () => {
    // accountId = process.env.REACT_APPg5b657_ACCOUNT_ID;
    let token = getUserToken();
    if (token) axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.baseURL = "https://api.kobokist.thehebrewsshelters.com/api";
    //axios.defaults.baseURL = "http://10.211.55.4/api";

    // Intercept 401 HTTP Error code in API
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          //No response
          // notify("Seems like you're offline, check internet connection")
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.config.url !== "/"
        ) {
          // console.log(error, "1!!!!!!!!!!")
          // rememberRoute();
          logOutUser();
        }
        return Promise.reject(error.response);
      }
    );
  },
  signIn: (data) => {
    return axios.post(`/Admin/AdminLogin`, data, headers);
  },
  getAllServiceCategories: () => {
    return axios.get(`/ServiceCategory/GetAllServiceCategories`, headers);
  },

  createServiceCategory: (data) => {
    return axios.post(`/ServiceCategory/CreateServiceCategory`,data, headers);
  },
  getPlansByLocation: (categoryId, location) => {
    return axios.get(`/ServiceCategoryPricing/GetAssociatedPlansByLocation?location=${location}&serviceCategoryId=${categoryId}`, headers);
  },
  getServiceLocationStrings: (categoryId) => {
    return axios.get(`/ServiceCategory/GetAllServcicingLocations?serviceCategoryId=${categoryId}`, headers);
  },
  addServiceLocations: (data, serviceCategoryId) => {
    return axios.post(`/ServiceCategory/AddServiceLocations?serviceCategoryId=${serviceCategoryId}`,data, headers);
  },
  applyPricingToLocation: (data) => {
    return axios.post(`/ServiceCategoryPricing/ApplyServiceLocationPricing`, data, headers);
  },
  getServiceRequests: (pageNumber, pageSize) => {
    return axios.get(`/Admin/GetAllServiceRequests?pageNumber=${pageNumber}&pageSize=${pageSize}`, headers);
  },
  getPendingServiceRequests: (pageNumber, pageSize) => {
    return axios.get(`/Admin/GetServiceRequestsPendingAllocation?pageNumber=${pageNumber}&pageSize=${pageSize}`, headers);
  },
  allocateServicePerson: (data) => {
    return axios.post(`/Admin/AllocateServicePerson`,data, headers);
  },
  getKycRequestList: () => {
    return axios.get(`/Admin/GetKycRequestList`, headers);
  },
  approveKycRequest: (data) => {
    return axios.post(`/Admin/ManualKycVerification?kycId=${data}`, headers);
  },
  updateServiceCategory: (data) => {
    return axios.post(`/Admin/UpdateServiceCategory`,data, headers);
  },
  updatePricing: (data, serviceCategoryPricePlanId) => {
    return axios.post(`/ServiceCategoryPricing/UpdateServiceLocationPricing?serviceCategoryPricePlanId=${serviceCategoryPricePlanId}`, data, headers);
  },
  deletePricingPlan: (data) => {
    return axios.post(`/Admin/DeactivatePricingPlan?pricingplanId=${data}`, headers);
  },
  getTransactionList: () => {
    return axios.get(`/Admin/GetSuccessfulTransactionList`, headers);
  },
  getDashboardStatistics: () => {
    return axios.get(`/Admin/GetDashboardStatistics`, headers);
  },
  getFishShopList: (data) => {
    return axios.get(`/FishShop/GetFishShop`, data, headers);
  },
  createFishItem: (data) => {
    return axios.post(`/FishShop/AddFishItem`, data, headers);
  },
  deleteFishItem: (data) => {
    return axios.post(`/FishShop/DeleteItemFromFishShop?fishShopId=${data}`, headers);
  },
  updateFishItem: (data) => {
    return axios.post(`/FishShop/UpdateFishShop`, data, headers);
  },

  createPropertyItem: (data) => {
    return axios.post(`/KoboProperties/AddKoboProperty`, data, headers);
  },
  getPropertyList: (data) => {
    return axios.get(`/KoboProperties/GetKoboProperties`, data, headers);
  },
  deletePropertyItem: (data) => {
    return axios.post(`/KoboProperties/DeletePropertyItem?id=${data}`, headers);
  },
  updatePropertyItem: (data) => {
    return axios.post(`/KoboProperties/UpdateProperty`, data, headers);
  },

};

export default Endpoint;
