import React, { useEffect, useState, useRef } from "react";

import withLayout from "../../layouts/withLayout";
import Sidenav from "../../layouts/adminLayout/sidenav";
import { Card, Table, Button, message, Popconfirm, Badge, Modal, Space, Tag } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
    MdOutlinePersonRemoveAlt1,
    MdOutlinePersonOff,
    MdCheckCircle,
    MdOutlineError,
    MdOutlinePersonOutline,
    MdOutlinePerson,
    MdGroups,
    MdGroupOff,
    MdGroup,
} from "react-icons/md";
import Endpoint from "../../utils/endpoint";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import $ from "jquery"
import { ScaleLoader } from "react-spinners";
import { enquireScreen } from 'enquire-js';
import { ToastContainer, toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import avatar from "../../assets/images/koboL1.png"




const KYCVerificationRequests = () => {
    const [tableContent, setTableContent] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentSearchQuery, setCurrentSearchQuery] = useState("");
    const [cardDetails, setCardDetails] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeRequest, setactiveRequest] = useState({});
    const [allocatedServicePerson, setallocatedServicePerson] = useState({});
    const [inputValues, setInputValues] = useState({
        checkbox: false,
        text: '',
    });
    const printRef = useRef();




    const cancel = (e) => {
        console.log(e);
        // message.error("Click on No");
    };

  

    const columns = [
        {
            title: "NAME",
            dataIndex: "fullname",
            key: "fullname",
        },
        {
            title: "NIN",
            dataIndex: "nin",
            key: "nin",
        },
        {
            title: "BVN",
            dataIndex: "bvn",
            key: "bvn",
        },
        {
            title: "Confidence score",
            dataIndex: "confidence_score",
            key: "confidence_score",
        },
        
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
    ];
    // const data = tableContent?.map((item, index) => {
    //     return {
    //         fullname: item?.fullname,
    //         serviceDate: item?.serviceDate,
    //         requestedDate: item?.requestedDate,
    //         emailAddress: item?.emailAddress,
    //         servicedStatus: item?.servicedStatus == 1 ? <Badge.Ribbon
    //             text={item.status}
    //             color="geekblue"
    //             style={{ fontSize: "12px", marginTop: "-18px" }}
    //         >

    //         </Badge.Ribbon> : item?.servicedStatus == 2 ? <Badge.Ribbon
    //             text={item.status}
    //             color="green"
    //             style={{ fontSize: "12px", marginTop: "-18px" }}
    //         >

    //         </Badge.Ribbon> : null,
    //         phone: item?.phone,
    //         lastLogin: item?.lastLogin,
    //         action: (
    //             <div>
    //                 {/* <Flex gap="5"> */}
    //                 {/* {item.servicedStatus == 1 &&  */}
    //                 <a onClick={() => showModal(item)} to={`#`} className="text-primary">
    //                     manage
    //                 </a>
    //                 {/* } */}

    //             </div>
    //         ),
    //     };
    // });
    const getAll = () => {
        // setCurrentPage(pageNumber);
        Endpoint.getKycRequestList()
            .then((res) => {
                console.log(res?.data, "resUser");
                // setCardDetails(res?.data);
                let mappedData = res?.data?.map((item, i) => {
                    return {
                        fullname: item?.fullname,
                        nin: item?.nin,
                        confidence_score: item?.confidence_score ?? "-",
                        bvn: item?.bvn == null || item?.bvn == "" ? "-" : item?.bvn,
                        status:<>
                            {item?.kycVerificationStatus == 1 ?
                                <Tag color={"green"}>
                            verified
                        </Tag>
                         :
                            item?.kycVerificationStatus == 2 ?
                                    <Tag color={"blue"}>
                                        requested
                                    </Tag>
                            : 
                             
                            item?.kycVerificationStatus == 0 ?
                            <Tag color={"volcano"}>
                                unverified
                            </Tag>
                            :
                            
                            null}
                        </>,

                            
                                action: (
                                    <div>
                                        {/* <Flex gap="5"> */}
                                        {/* {item.servicedStatus == 1 &&  */}
                                        <a onClick={() => showModal(item)} to={`#`} className="text-primary">
                                            manage
                                        </a>
                                        {/* } */}

                                    </div>
                                ),
                    };
                })
                setTableContent(mappedData);
                $('#preloader').delay(450).fadeOut('slow');
            })
            .catch((err) => {
                console.log(err, "Err");
                $('#preloader').delay(450).fadeOut('slow');
            });
    };



    useEffect(() => {
        enquireScreen((b) => {
            setIsMobile(b)
        });
        getAll();
    }, []);
    function clearAllInputs() {
        const inputs = document.querySelectorAll('input, textarea, select');

        inputs.forEach(input => {
            if (input.type === 'checkbox' || input.type === 'radio') {
                input.checked = false;
            } else {
                input.value = '';
            }
        });
    }

    function isNullOrEmpty(value) {
        return value === undefined || value === null || value === "";
    }

    const handleKycVerification = () => {

        $('#preloader').fadeIn('slow');
       
        Endpoint.approveKycRequest(activeRequest?.id)
            .then((res) => {
                console.log(res?.data)
                setIsModalOpen(false);
                toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>You have Successfully verified user!</p>);
                $('#preloader').fadeOut('slow');
                getAll()
            })
            .catch((err) => {
                console.log(err)
                $('#preloader').fadeOut('slow');
            })
    }
    const showModal = (data) => {
        console.log(data)
        setactiveRequest(data)
        // setallocatedServicePerson(data?.servicePersonAllocations)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        clearAllInputs()
        setIsModalOpen(false);
    };
    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };
    return (
        <div>
            <Modal className="outfit-500" title={"Confirm Verification"} footer={[<Space>
                <button disabled={activeRequest?.servicedStatus == 2} onClick={() => handleKycVerification()} className="btn btn-primary mr-1" style={{ marginRight: "2px" }}>Verify User <i className="fa fa-check-circle"/></button> <button style={{ marginRight: "3px" }} onClick={() => handleCancel()} className="btn btn-danger mr-1">Cancel</button>
                {/* <>
                    {activeRequest?.servicedStatus == 2 && <button style={{ fontSize: "11px" }} onClick={generatePDF} className="vtm-btn-outline btn btn-primary">
                        Download PDF <i className="fa fa-pdf" />
                    </button>}
                </> */}
            </Space>
            ]} open={isModalOpen} onOk={handleOk} closeIcon={false} onCancel={handleCancel}>
                <p className="outfit-400">Are you sure you want to manually verify this user?</p>
                
            </Modal>
            <div id="preloader">
                <div id="status">
                    <ScaleLoader color="#fff" />
                </div>
            </div>
            <div className="row g-3 justify-content-center">
                {/* <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial active-blue-bg">
                <MdBarChart
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Events</p>
              <h3 style={{ lineHeight: "5px" }}>678</h3>
            </div>
          </div>
        </div> */}

                <div className="col-12 col-md-3 shadow stat-card-dash">
                    <div className="row">
                        <div className="col-4">
                            <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                                <MdGroups
                                    style={{ fontSize: "25px", color: "#422AFB" }}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Requests</p>
                            <h3 style={{ lineHeight: "5px" }}>-</h3>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-md-3 shadow stat-card-dash">
                    <div className="row">
                        <div className="col-4">
                            <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                                <MdGroupOff
                                    style={{ fontSize: "25px", color: "#422AFB" }}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <p className="outfit-500" style={{ color: "#a3aed0" }}>Pending Requests</p>
                            <h3 style={{ lineHeight: "5px" }}>-</h3>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3 shadow stat-card-dash">
                    <div className="row">
                        <div className="col-4">
                            <div className="stat-radial active-green-bg">
                                <MdGroup
                                    style={{ fontSize: "25px", color: "#fff" }}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <p className="outfit-500" style={{ color: "#a3aed0" }}>Verified Requests</p>
                            <h3 style={{ lineHeight: "5px" }}>-</h3>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-2 shadow stat-card-dash">
                    <div className="row">
                        <div className="col-4">
                            <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                                <MdGroupOff
                                    style={{ fontSize: "25px", color: "#422AFB" }}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <p className="outfit-500" style={{ color: "#a3aed0" }}>Rejected Requests</p>
                            <h3 style={{ lineHeight: "5px" }}>-</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-12 mt-4">
                    <Card>
                        <p className="outfit-600" style={{ fontSize: "18px", fontWeight: "700" }}>
                           KYC Verification Requests
                        </p>
                        {/* <Table columns={columns} dataSource={data} /> */}
                        {/* {isMobile ? 
            <h5>Tables are not shown on mobile devices</h5> 
            :  */}
                        <Table className="table-responsive" columns={columns} dataSource={tableContent} />
                        {/* } */}
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default withLayout(Sidenav)(KYCVerificationRequests);
