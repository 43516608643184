import React, { useEffect, useState } from 'react';
import "../../../assets/css/bubbles/bubbles.css"
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import picOne from "../../../assets/images/img11.png"
import picTwo from "../../../assets/images/img22.png"
import picGirls from "../../../assets/images/girls1.png"
import spaceRocket from "../../../assets/images/rocket.png"
function MainBanner() {
    const [init, setInit] = useState(false);
    const gotoapp = () => {
        var appStoreUrl = 'https://apps.apple.com/app/id/1577728166';
        window.location.href = appStoreUrl
    }
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
                <div className='container' style={{position:"relative", width:"100%", minHeight:"90vh"}}>
                  
                        {init && <Particles
                            id="tsparticles"
                            // style={{height:}}
                            particlesLoaded={particlesLoaded}
                            options={{
                                background: {
                                    color: {
                                        value: "#44158d",
                                    },
                                    position:"absolute"
                                },
                                fpsLimit: 120,
                                interactivity: {
                                    events: {
                                        onClick: {
                                            enable: true,
                                            mode: "push",
                                        },
                                        onHover: {
                                            enable: false,
                                            mode: "repulse",
                                        },
                                        resize: true,
                                    },
                                    modes: {
                                        push: {
                                            quantity: 9,
                                        },
                                        repulse: {
                                            distance: 200,
                                            duration: 8,
                                        },
                                    },
                                },
                                particles: {
                                    color: {
                                        value: "#ffffff",
                                    },
                                    links: {
                                        color: "#ffffff",
                                        distance: 250,
                                        enable: true,
                                        opacity: 0.3,
                                        width: 1,
                                    },
                                    move: {
                                        direction: "none",
                                        enable: true,
                                        outModes: {
                                            default: "bounce",
                                        },
                                        random: false,
                                        speed: .5,
                                        straight: false,
                                    },
                                    number: {
                                        density: {
                                            enable: true,
                                            area: 800,
                                        },
                                        value: 100,
                                    },
                                    opacity: {
                                        value: 0.3,
                                    },
                                    shape: {
                                        type: "circle",
                                    },
                                    size: {
                                        value: { min: 1, max: 5 },
                                    },
                                },
                                detectRetina: true,
                            }}
                        />
                        }
                    <div className='main_banner_lloy_content' style={{ zIndex: 2, position: "absolute", width:"100%" }}>
              <div className='row'>
                <div className='col-sm-6'>
                        <img src={picOne} className='mr-2' style={{ width: "100px" }} />
                        {/* <h2>&nbsp;</h2> */}

                        {/* <img src={spaceRocket} style={{ width: "60px" }} /> */}
                </div>
                    <div className='col-sm-2 mt-2'>
                        {/* <img src={picOne} style={{ width: "100px" }} /> */}
                        <img src={spaceRocket} className='mobile_float_end' style={{ width: "5vmin" }} />
                    </div>
                    <div className='col-sm-4'>
                        <img src={picGirls} style={{ width: "120px" }} className='mobile_hider' />

                    </div>
              </div>
                        <div className='row'>
                            <div className='col-lg-10 col-sm-12'>
                      
                                <p className='outfit_head text-white main_banner_text text-left'>
                                  Welcome To Xplur Socials
                                </p>
                        <p className='outfit_text text-left blue-text-main' style={{marginTop:"-15px"}}>
                            Your passport to social adventure
                        </p>
                        
                            </div>
                  
                  <div className='col-12 mt-3'>
                        <button onClick={() => gotoapp()} className='btn btn-primary float-start get_app_btn'>
                            Get the app
                        </button>
                  </div>
                           
                        </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        {/* <img src={picOne} style={{ width: "50px" }} /> */}
                    </div>
                    <div className='col-sm-6'>
                        <img src={picTwo} className='mobile_float_end' style={{ width: "120px" }} />
                        {/* <img src={picGirls} style={{ width: "120px" }} /> */}
                    </div>
                </div>
               
                    </div>
                </div>
    );
}

export default MainBanner;
