import React from "react";
import logoLloydant from "../../assets/images/xplurlogo.png";
import { MenuOutlined } from "@ant-design/icons";

function FrontHeader() {
  return (
    <div>
      <div className="lloy_navbar">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img
                alt="logo"
                className="lloydant_header_logo"
                src={logoLloydant}
              />
            </a>
            {/* <MenuOutlined color='#fff' className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" /> */}
            {/* <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Our Products</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Careers</a>
                                </li>
                                <li className="nav-item dropdown">
                                    
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="/">Action</a></li>
                                        <li><a className="dropdown-item" href="/">Another action</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" href="/">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link disabled" href='/' aria-disabled="true">Disabled</a>
                                </li>
                            </ul>
                           
                        </div> */}
          </div>
        </nav>
        {/* <img src={logoLloydant} className='lloydant_header_logo'/>
                <h1 style={{fontSize:16}}>&nbsp;</h1> */}
      </div>
    </div>
  );
}

export default FrontHeader;
