import React, { useEffect, useState, useRef } from "react";

import withLayout from "../../layouts/withLayout";
import Sidenav from "../../layouts/adminLayout/sidenav";
import { Card, Table, Button, message, Popconfirm, Badge, Modal, Space } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlinePersonRemoveAlt1,
  MdOutlinePersonOff,
  MdCheckCircle,
  MdOutlineError,
  MdOutlinePersonOutline,
  MdOutlinePerson,
  MdGroups,
  MdGroupOff,
  MdGroup,
} from "react-icons/md";
import Endpoint from "../../utils/endpoint";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import $ from "jquery"
import { ScaleLoader } from "react-spinners";
import { enquireScreen } from 'enquire-js';
import { ToastContainer, toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import avatar from "../../assets/images/koboL1.png"
const UserManagement = () => {
  const [tableContent, setTableContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [cardDetails, setCardDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRequest, setactiveRequest] = useState({});
  const [allocatedServicePerson, setallocatedServicePerson] = useState({});
  const [inputValues, setInputValues] = useState({
    checkbox: false,
    text: '',
  });
  const printRef = useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;

    // Adjust the scale factor here
    const canvas = await html2canvas(element, { scale: 2 });

    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('download.pdf');
  };
  const generatePDF = () => {
    const doc = new jsPDF();

    // Set watermark
    doc.setTextColor(150);
    doc.setFontSize(50);
    doc.text('KoboKist', 35, 150, { angle: 45 });

    // Reset text color
    doc.setTextColor(0);

    // Add the Service Agency header
    doc.setFontSize(24);
    doc.setTextColor(33, 150, 243); // Blue color
    doc.text("Service Details", 20, 30);

    // Draw a line below the header
    doc.setLineWidth(1);
    doc.setDrawColor(33, 150, 243);
    doc.line(20, 35, 190, 35);

    // Add avatar image
    doc.addImage(avatar, 'PNG', 160, 45, 30, 30);

    // Adding service person data
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`Name: ${allocatedServicePerson?.name}`, 20, 60);
    doc.text(`Service Code: ${allocatedServicePerson?.serviceAllocationCode}`, 20, 70);
    doc.text(`Service Type: ${allocatedServicePerson?.designation}`, 20, 80);
    //doc.text(`Estimated Arrival Time: ${allocatedServicePerson?.estimatedArrivalTime}`, 20, 90);
    doc.text(`Service Date: ${activeRequest.serviceDate}`, 20, 90);
    doc.text(`Date Requested: ${activeRequest?.requestedDate}`, 20, 100);
    doc.text(`Customer Name: ${activeRequest?.customerName}`, 20, 110);
    doc.text(`Customer Contact: ${activeRequest?.customerPhone != null ? activeRequest?.customerPhone : "-"}`, 20, 120);
    
   // doc.text(`Experience Level: ${servicePerson.experienceLevel}`, 20, 100);
    //doc.text(`Contact: ${allocatedServicePerson?.contact}`, 20, 110);

    // Adding a note for verification
    doc.setFontSize(14);
    doc.setTextColor(33, 150, 243); // Blue color for the note
    doc.text("Note:", 20, 140);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text("Please verify the service person by asking for the service code", 20, 150);
    doc.text("and ensure it matches the one provided above.", 20, 155);

    // Save the PDF
    doc.save('Service_Person_Details.pdf');
  };

// Sample service person data
const servicePerson = {
  name: "John Doe",
  serviceCode: "ABC123",
  serviceType: "Plumbing",
  scheduledTime: "2024-08-20 10:00 AM",
  experienceLevel: "5 Years",
  contactInfo: "+1234567890"
};
  const cancel = (e) => {
    console.log(e);
    // message.error("Click on No");
  };

  const data = tableContent?.map((item, index) => {
    let verifyIcon = item?.isVerified ? (
      <MdCheckCircle style={{ color: "green", fontSize: 20 }} />
    ) : (
      <MdOutlineError style={{ color: "red", fontSize: 20 }} />
    );
    let servicedStatusIcon = item?.servicedStatus == 1 ? (
      <Badge.Ribbon
        text="Suspended"
        color="red"
        style={{ fontSize: "12px", marginTop: "-18px" }}
      ></Badge.Ribbon>
    ) : (
      <Badge.Ribbon
        text="Active"
        color="green"
        style={{ fontSize: "12px", marginTop: "-18px" }}
      ></Badge.Ribbon>
    );
    let blockIcon = item?.active ? (
      <Badge.Ribbon
        text="Active"
        color="green"
        style={{ fontSize: "12px", marginTop: "-18px" }}
      ></Badge.Ribbon>
    ) : (
      <Badge.Ribbon
        text="Blocked"
        color="red"
        style={{ fontSize: "12px", marginTop: "-18px" }}
      ></Badge.Ribbon>
    );
    const userId = item?.id;
    return {
      serviceName: item?.serviceName,
      serviceDate: item?.serviceDate,
      requestedDate: item?.requestedDate,
      emailAddress: item?.emailAddress,
      servicedStatus: item?.servicedStatus == 1 ? <Badge.Ribbon
        text={item.status}
        color="geekblue"
        style={{ fontSize: "12px", marginTop: "-18px" }}
      >
       
      </Badge.Ribbon> : item?.servicedStatus == 2 ? <Badge.Ribbon
        text={item.status}
        color="green"
        style={{ fontSize: "12px", marginTop: "-18px" }}
      >

      </Badge.Ribbon> : null,
      active: <div>{blockIcon}</div>,
      phone: item?.phone,
      lastLogin: item?.lastLogin,
      action: (
        <div>
          {/* <Flex gap="5"> */}
          {/* {item.servicedStatus == 1 &&  */}
          <a onClick={() => showModal(item)} to={`#`} className="text-primary">
            manage
          </a>
          {/* } */}
          
        </div>
      ),
    };
  });

  const columns = [
    {
      title: "SERVICE NAME",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "SERVICE DATE",
      dataIndex: "serviceDate",
      key: "serviceDate",
    },
    {
      title: "DATE REQUESTED",
      dataIndex: "requestedDate",
      key: "requestedDate",
    },
    // {
    //   title: "VERIFIED",
    //   dataIndex: "isVerified",
    //   key: "isVerified",
    // },
    {
      title: "STATUS",
      dataIndex: "servicedStatus",
      key: "servicedStatus",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
    },
    // {
    //   title: "LAST LOGIN",
    //   dataIndex: "lastLogin",
    //   key: "lastLogin",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const getAll = () => {
    // setCurrentPage(pageNumber);
    Endpoint.getServiceRequests(1, 10)
      .then((res) => {
        console.log(res.data, "resUser");
        setCardDetails(res.data);
        setTableContent(res.data?.data);
        $('#preloader').delay(450).fadeOut('slow');
      })
      .catch((err) => {
        console.log(err, "Err");
      });
  };

  const blockUserFunc = (userId) => {
    Endpoint.postBlockUser(userId).then((res) => {
      console.log(res.data, "resBlocked");
    });
    getAll(currentPage, pageSize, currentSearchQuery).catch((err) => {
      console.log(err, "Err");
    });
  };

  const verifyUserFunc = (userId) => {
    console.log(userId, "can you seee the userid?");
    Endpoint.postVerifyUser(userId).then((res) => {
      console.log(res?.data, "verifieddd");
    });
    getAll(currentPage, pageSize, currentSearchQuery).catch((err) => {
      console.log(err, "Error");
    });
  };

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(b)
    });
    getAll(currentPage, pageSize);
  }, []);
  function clearAllInputs() {
    const inputs = document.querySelectorAll('input, textarea, select');

    inputs.forEach(input => {
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.checked = false;
      } else {
        input.value = '';
      }
    });
  }

  function isNullOrEmpty(value) {
    return value === undefined || value === null || value === "";
  }

  const handleAllocateServicePerson = () => {

    if (isNullOrEmpty(inputValues?.name) || isNullOrEmpty(inputValues?.designation) || isNullOrEmpty(inputValues?.phone)){
      alert("make sure neither of the name, designation and phone is empty")
      return
    }
    $('#preloader').fadeIn('slow');
    const payload = {
      "serviceRequestId": activeRequest?.serviceRequestId,
      "servicePersonName": inputValues?.name,
      "allocationCode": "",
      "designation": inputValues?.designation,
      "contact": inputValues?.phone,
      "estimatedArrivalTime": inputValues?.arrivaltime,
      "serviceNote": inputValues?.servicenote
    }
    Endpoint.allocateServicePerson(payload)
    .then((res) => {
      console.log(res?.data)
      setIsModalOpen(false);
      toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Allocation was successfull!</p>);
      $('#preloader').fadeOut('slow');
      getAll()
    })
    .catch((err) => {
      console.log(err)
      $('#preloader').fadeOut('slow');
    })
  }
  const showModal = (data) => {
    console.log(data)
    setactiveRequest(data)
    setallocatedServicePerson(data?.servicePersonAllocations)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    clearAllInputs()
    setIsModalOpen(false);
  };
  const handleInput = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };
  return (
    <div>
      <Modal className="outfit-500" title={"Servie Person Allocation"} footer={[<Space>
        <button disabled={activeRequest?.servicedStatus == 2} onClick={() => handleAllocateServicePerson()} className="btn btn-primary mr-3" style={{ marginRight: "9px" }}>Allocate</button> <button style={{ marginRight: "9px" }} onClick={() => handleCancel()} className="btn btn-danger mr-3">Cancel</button>
        <>
          {activeRequest?.servicedStatus == 2 && <button style={{fontSize:"11px"}} onClick={generatePDF} className="vtm-btn-outline btn btn-primary">
            Download PDF <i className="fa fa-pdf" />
          </button>}
        </>
      </Space>
      ]} open={isModalOpen} onOk={handleOk} closeIcon={false} onCancel={handleCancel}>

        <div className="row justify-content-center vtm-your-div-class" ref={printRef}>
          <div className="col-12">
            {activeRequest?.servicedStatus == 2 
            ?
              <div className="card">
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Address</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.serviceAddress} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Type</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.serviceName} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Date Requested</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.requestedDate} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Date</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.serviceDate} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>

                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Allocated Service Person</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.servicePersonAllocations?.name} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>

                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Person Designation</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.servicePersonAllocations?.designation} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>

                
              </div>
              :
              <div className="card">
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Name</span>
                  <input onChange={(e) => handleInput(e)} defaultValue={""} name="name" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Phone</span>
                  <input onChange={(e) => handleInput(e)} name="phone"  type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Designation</span>
                  <input onChange={(e) => handleInput(e)} name="designation"  type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Estimated Arrival Time</span>
                  <input onChange={(e) => handleInput(e)} name="arrivaltime" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Note</span>
                  <input onChange={(e) => handleInput(e)} name="servicenote" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Type</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.serviceName} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div class="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-sm">Service Address</span>
                  <input readOnly type="text" className="form-control" value={activeRequest?.serviceAddress} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                </div>
              </div>
            }
            
        
          </div>
        </div>
      </Modal>
      <div id="preloader">
        <div id="status">
          <ScaleLoader color="#fff" />
        </div>
      </div>
      <div className="row g-3 justify-content-center">
        {/* <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial active-blue-bg">
                <MdBarChart
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Events</p>
              <h3 style={{ lineHeight: "5px" }}>678</h3>
            </div>
          </div>
        </div> */}

        <div className="col-12 col-md-3 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                <MdGroups
                  style={{ fontSize: "25px", color: "#422AFB" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Requests</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>


        <div className="col-12 col-md-3 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                <MdGroupOff
                  style={{ fontSize: "25px", color: "#422AFB" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Pending Requests</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial active-green-bg">
                <MdGroup
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Active Requests</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                <MdAttachMoney
                  style={{ fontSize: "25px", color: "#422AFB" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Weekly Revenue</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>

      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Card>
            <p className="outfit-600" style={{ fontSize: "18px", fontWeight: "700" }}>
              Home Service Request Management
            </p>
            {/* <Table columns={columns} dataSource={data} /> */}
            {/* {isMobile ? 
            <h5>Tables are not shown on mobile devices</h5> 
            :  */}
            <Table className="table-responsive" columns={columns} dataSource={data} />
            {/* } */}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default withLayout(Sidenav)(UserManagement);
