import React from 'react';
import { Tree } from 'antd';
import { states } from '../helpers/statestore';
import { ToastContainer, toast } from 'react-toastify';



const TreeCompo = ({ showfetchFunc, fetchFunc, disabledArr, disableState, addFunc, checkedArr }) => {
    // console.log(disabledArr, "servicingArr")
    const onSelect = (selectedKeys, info) => {
        // console.log('selected', selectedKeys, info?.node);
        console.log('selected', info?.node);
        if (info?.node?.key?.includes("statekey")){
            return;
        }
        fetchFunc(info?.node?.title)
    };

    const onCheck = (checkedKeys, info) => {
        console.log('onCheck', info?.node);
        console.log('checkedKeys', checkedKeys);
        addFunc(checkedKeys)
    };
    const treeData = states?.map((x, i) => ({
        title: x.name,
        key: `statekey_${x.name}`,
        // disabled: true,
        children: x.lgas.map((l, k) => ({
            title: l,
            key: l, // Ensure unique keys
            // disableCheckbox: disableState == true && disabledArr.some(x => x == l) ? true : false
            disableCheckbox: disableState == 2 && (disabledArr.some(x => x == l) ? false : true)
            // disableCheckbox: disableState == 2 && (disabledArr.some(x => x == l) ? false : true) ? disableState == 1 && (disabledArr.some(x => x == l) ? true : false) : null
        }))
    }));
    return (
        <Tree
            checkable
            defaultExpandedKeys={disabledArr}
            // defaultSelectedKeys={disabledArr}
            defaultCheckedKeys={checkedArr}
            onSelect={showfetchFunc ? onSelect : null}
            onCheck={onCheck}
            treeData={treeData}
            className='outfit-500 '
        />
    );
};

export default TreeCompo;
