import React, { useEffect, useState } from "react";

import withLayout from "../../layouts/withLayout";
import Sidenav from "../../layouts/adminLayout/sidenav";
import { Card, Table, Modal, Upload, Image, Popover, Badge, Tag, Spin, Space } from "antd";
import { InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { states } from '../../helpers/statestore';
import emptyState from "../../assets/images/empty-state.png"

import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
    MdOutlinePersonRemoveAlt1,
    MdOutlinePersonOff,
    MdGroups,
    MdGroupOff,
    MdGroup,
    MdAddCircle,
    MdAddCircleOutline,
    MdOutlineSettingsSuggest,
    MdArrowDropDownCircle,
    MdChecklist,
    MdCheckCircleOutline,
} from "react-icons/md";
import Endpoint, { baseContentURL } from "../../utils/endpoint";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Spinner from "../../components/spinner";
import { SphereSpinner } from "react-spinners-kit";
import { ScaleLoader } from "react-spinners";
import $ from "jquery"
import { enquireScreen } from 'enquire-js';
import TreeCompo from "../../components/tree";
import { ToastContainer, toast } from 'react-toastify';
import { nairaFormat } from "../../utils/helpers";

const { Dragger } = Upload;


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const PropertyManagement = () => {
    const [emptyStateShow, setemptyStateShow] = useState(true);
    const [tableContent, setTableContent] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentSearchQuery, setCurrentSearchQuery] = useState("");
    const [cardDetails, setCardDetails] = useState([]);
    const [locationPayloadArr, setlocationPayloadArr] = useState([]);
    const [open, setOpen] = useState(false);
    const [basicSpin, setbasicSpin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [openPricePlan, setopenPricePlan] = useState(false);
    const [openPriceSetup, setopenPriceSetup] = useState(false);
    const [openAddServiceLocation, setopenAddServiceLocation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setpreviewImage] = useState("");
    const [saving, setsaving] = useState(false);
    const [visibilityIndicator, setvisibilityIndicator] = useState(1);
    const [openPopIndex, setOpenPopIndex] = useState(null);
    const [selectedServiceCategory, setselectedServiceCategory] = useState({});
    const [pricePlanArr, setpricePlanArr] = useState([]);
    const [newLocationsArr, setnewLocationsArr] = useState([]);
    const [markedServicingArr, setmarkedServicingArr] = useState([]);
    const [showLocationList, setshowLocationList] = useState(false);
    const [pricingSetupCard, setpricingSetupCard] = useState(false);
    const [featureList, setfeatureList] = useState([]);
    const [deletePlanModal, setdeletePlanModal] = useState(false);
    const [categogryId, setcategogryId] = useState(0);
    const [modalText, setmodalText] = useState("");
    const [pricingPlanId, setpricingPlanId] = useState(0);
    const [pricingPlanAction, setpricingPlanAction] = useState("Apply ");
    const [inputValues, setInputValues] = useState({
        checkbox: false,
        text: '',
    });
    const [openPop, setOpenPop] = useState(false);
    // const hidePop = () => {
    //     setOpenPop(false);
    // };
    const hidePop = () => {
        setOpenPopIndex(null);
    };
    // const handleOpenPopChange = (newOpen) => {
    //     setOpenPop(newOpen);
    // };
    const handleOpenPopChange = (index) => {
        setOpenPopIndex(openPopIndex === index ? null : index);
    };
    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };
    const clearImage = () => {
        setpreviewImage("")
        setFileList([])
    }
    const showModal = () => {
        setIsModalOpen(true);
        setmodalText("Add")
        setpreviewImage("")
        setInputValues({
            categoryName: "",
            categoryCaption: "",
            description: "",
            badgeValue: ""
        });
    };


    const showAddServiceLocationModal = () => {
        setopenAddServiceLocation(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setopenAddServiceLocation(false);
        setopenPriceSetup(false);
        setdeletePlanModal(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setopenPricePlan(false);
        setopenAddServiceLocation(false);
        setopenPriceSetup(false);
        setpricingSetupCard(false)
        setdeletePlanModal(false)
    };
    const handleCancelDelete = () => {
        setdeletePlanModal(false)
        setpricingPlanId(0)
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 2000);

    };
    const handleChange = ({ fileList: newFileList }) => {

        console.log(newFileList, "newFileList")
        setFileList(newFileList)
        //if (!file.url && !file.preview) {
        //let yyty = await getBase64(file.originFileObj);
        handlePreview(newFileList[0])
        //}
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setpreviewImage(file.url || file.preview);
    };
    const data = tableContent?.map((item, index) => {
        return {
            name: item?.name?.length > 30
                ? item?.name.substring(0, 30) + "..."
                : item?.name,
            variant: item?.extraInfo,
            price: item?.price > 0 ? nairaFormat(item?.price, 1) : 0,
            badgeText:
                item?.badgeText?.length > 40
                    ? item?.badgeText.substring(0, 40) + "..."
                    : item?.badgeText,
            hostName: item?.hostName,
            description: item?.description?.length > 20 ? item?.description.substring(0, 20) + "..." : item?.description,
            image: (
                <Image
                    width={30}
                    src={baseContentURL + item?.imageUrl1}
                    style={{ borderRadius: "25%" }}
                />
            ),
            icon: (
                // <button className="btn btn-success btn-sm outfit-500">Manage <i class="fa-solid fa-square-pen"/></button>
                <div>
                    {/* <MdOutlineSettingsSuggest
                        style={{ height: "32px", width: "32px" }}
                        color="#2a67fb"
                    /> */}
                    {/* <Tag onClick={() => toggleVisibility(item, 2)} color={'green'} className="outfit-400" key={index} style={{fontSize:"12px"}}>
                        <i class="fa-solid fa-location-dot" /> 
                    </Tag> */}
                    <Tag onClick={() => openEditCategory(item)} color={'geekblue'} className="outfit-400" key={index} style={{ fontSize: "12px" }}>
                        <i class="fa-solid fa-edit" />
                    </Tag>
                    <Tag onClick={() => promptDelete(item)} color={'volcano'} className="outfit-500" key={index} style={{ fontSize: "12px" }}>
                        {/* Pricing  */}
                        {/* <i class="fa-solid fa-coins"/> */}
                        <i class="fa fa-trash" />

                    </Tag>

                </div>

            ),
        };
    });

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
        },
        // {
        //     title: "Badge Value",
        //     dataIndex: "badgeText",
        //     key: "badgeText",
        // },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     key: "description",

        // },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            // render: () => <a href="#">Edit</a>,
        },
        // {
        //     title: "Service Locations",
        //     dataIndex: "servicingStates",
        //     key: "servicingStates",
        // },
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            // render: () => <a href="#">Edit</a>,
        },
    ];



    const fetchPropertyItems = () => {
        $('#preloader').fadeIn();

        Endpoint.getPropertyList()
            .then((res) => {
                console.log(res.data, "res");
                setCardDetails(res.data);
                setTableContent(res.data);
                $('#preloader').delay(450).fadeOut('slow');
            })
            .catch((err) => {
                console.log(err)
                $('#preloader').delay(450).fadeOut('slow');
            })
    };
    const openEditCategory = (data) => {
        console.log(data)
        setIsModalOpen(true);
        setmodalText("Update")
        setpreviewImage(baseContentURL + data?.imageUrl)
        setInputValues({
            name: data?.name,
            price: data?.price,
            description: data?.description,
            id: data?.id,
            variant: data?.extraInfo,
            badgeValue: data?.badgeText
        });

    }
    const handleUpdatePropertyItem = () => {
        setsaving(true)
        console.log(fileList[0])
        let formData = new FormData();

        formData.append("Id", inputValues?.id)
        formData.append("Name", inputValues?.name)
        formData.append("Price", inputValues?.price)
        formData.append("Description", inputValues?.description)
        formData.append("ImageUrl", fileList[0]?.originFileObj)


        Endpoint.updatePropertyItem(formData)
            .then((res) => {
                console.log(res?.data)
                if (res?.data) {
                    setsaving(false)
                    setIsModalOpen(false)
                    $('#preloader').fadeIn('slow');
                    fetchPropertyItems();
                }
                else {
                    setsaving(false)
                    setIsModalOpen(false)
                    fetchPropertyItems();
                    // $('#preloader').fadeIn('slow');
                    // alert("Something went wrong")
                }

            })
            .catch((err) => {
                console.log(err)
                setsaving(false)
                setIsModalOpen(false)
                fetchPropertyItems();

                // alert("Something went wrong")
            })
    }
    const handleCreatePropertyItem = () => {
        setsaving(true)
        console.log(fileList[0])
        let formData = new FormData();

        formData.append("Name", inputValues?.name)
        formData.append("Price", inputValues?.price)
        formData.append("Description", inputValues?.description)
        formData.append("ImageFile1", fileList[0]?.originFileObj)


        Endpoint.createPropertyItem(formData)
            .then((res) => {
                console.log(res?.data)
                if (res?.data) {
                    setsaving(false)
                    setIsModalOpen(false)
                    $('#preloader').fadeIn('slow');
                    fetchPropertyItems();
                }
                else {
                    setsaving(false)
                    setIsModalOpen(false)
                    $('#preloader').fadeIn('slow');
                    alert("Something went wrong")
                }

            })
            .catch((err) => {
                console.log(err)
                alert("Something went wrong")
            })
    }
    const toggleVisibility = async (data, indicator) => {
        console.log(data, "Selected$$$$$")
        setselectedServiceCategory(data)
        await fetchServiceLocationStrings(data?.id, indicator);

        // // setvisibilityIndicator(indicator)
        // if(indicator == 2){
        //     await fetchServiceLocationStrings(data?.id, indicator);
        //     // setopenPricePlan(true)
        // }
        // else if (indicator == 3) {
        //     await fetchServiceLocationStrings(data?.id, indicator);
        //     // setopenPricePlan(true)
        // }
    }



    const fetchServiceLocationStrings = (data, additionalProps) => {
        setshowLocationList(false)
        setopenPricePlan(false)
        setopenPriceSetup(false)
        $('#preloader').fadeIn('slow');

        Endpoint.getServiceLocationStrings(data)
            .then((res) => {
                setmarkedServicingArr(res?.data)
                setTimeout(() => {
                    setshowLocationList(true)
                    if (additionalProps == 2) {
                        setopenPricePlan(true)
                    }
                    else if (additionalProps == 3) {
                        setopenPriceSetup(true)
                    }
                    $('#preloader').delay(450).fadeOut('slow');
                }, 1000);
                console.log(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleSelectCatChange = (e) => {
        fetchServiceLocationStrings(parseInt(e.target.value))
        setcategogryId(parseInt(e.target.value))
    }


  
    const handleCheckLocation = (data) => {
        console.log(data, "checkitems")
        setnewLocationsArr(data)
    }
    useEffect(() => {

        enquireScreen((b) => {
            setIsMobile(b)
        });
        serverLoad()

    }, []);

  

    const promptDelete = (data) => {
        setdeletePlanModal(true)
        setInputValues({
            selected_plan_name: data?.name,
        });
        setpricingPlanId(data?.id)
    }
    const serverLoad = () => {
        // fetchServiceLocationStrings();
        fetchPropertyItems();
    }
    const handleAddFeatures = () => {
        if (featureList?.length >= 5) {
            toast.error(<p className="outfit-500 text-white text-center" style={{ fontSize: "15px" }}>Maximum pricing feaure allowed is five(5)</p>);
            return;
        }
        setfeatureList([...featureList, inputValues?.plan_features])
        document.getElementById("plan_features").value = ""
    }
    const handleRemoveFeatures = (data) => {
        var filterList = featureList?.filter((x) => x != data);
        setfeatureList(filterList);
    }
    const executeDelete = () => {
        setsaving(true)
        Endpoint.deletePropertyItem(pricingPlanId)
            .then((res) => {
                console.log()
                handleCancelDelete();
                handleCancel();
                alert("Successful")
                setsaving(false)
                window.location.reload(true)
            })
            .catch((err) => {
                console.log(err)
                setsaving(false)
            })
    }
    return (
        <>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <Modal footer={[
                <button onClick={() => executeDelete()} className="btn btn-primary outfit-400" disabled={saving} style={{ marginRight: "10px" }}>
                    {saving ? <ScaleLoader color="#fff" width={2} height={16} /> : <span>Confirm <i class="fa-regular fa-circle-check"></i></span>}
                </button>,
                <button className="btn btn-danger outfit-400">Cancel</button>]} open={deletePlanModal} onOk={handleCancelDelete} onCancel={handleCancelDelete}>
                <p className="outfit-400">Are sure you want to delete <b>{inputValues?.selected_plan_name}</b></p>
            </Modal>

            <Modal footer={[
                <button onClick={modalText == "Add" ? () => handleCreatePropertyItem() : () => handleUpdatePropertyItem()} onCancel={handleCancel} className="btn btn-primary outfit-400" disabled={saving} style={{ marginRight: "10px" }}>
                    {saving ? <ScaleLoader color="#fff" width={2} height={16} /> : <span>{modalText} <i class="fa-regular fa-circle-check"></i></span>}
                </button>,
                <button onClick={() => handleCancel()} className="btn btn-danger outfit-400">Cancel</button>]} title={<h3 className="outfit-500" style={{ fontSize: "20px" }}>{modalText} Property Item</h3>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="row mt-4">

                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Select Image <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            {!previewImage &&
                                <Dragger
                                    customRequest={dummyRequest}
                                    onChange={handleChange}
                                    maxCount={1}
                                // defaultFileList={fileList}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text outfit-400" style={{ fontSize: "12px" }}>Click or drag file to this area to upload</p>
                                    <span className='span'></span> <span className='span outfit-400'>(jpg, jpeg, png)</span>
                                    {/* <p className="ant-upload-hint">
                                Support for a single or bulk upload
                            </p> */}
                                </Dragger>
                            }

                            {previewImage &&

                                <div>
                                    <img src={previewImage} class="img-fluid" alt="..." />
                                    <button className="btn btn-danger btn-sm outfit-500 float-end mt-1" onClick={() => clearImage()}><i class="fa-solid fa-trash" /></button>
                                </div>
                            }
                        </div>

                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Name <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            <input onChange={(e) => handleInput(e)} name="name" value={inputValues?.name} type="text" className="form-control outfit-400" id="exampleFormControlInput1" />
                        </div>
                    </div>
                  
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Price <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            <input type="number" onChange={(e) => handleInput(e)} name="price" value={inputValues?.price} className="form-control outfit-400" id="" />
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Service Type <span style={{ fontSize: "11px" }}></span></label>
                            <select name="serviceType">
                                <option value={1}>Regular</option>
                                <option value={2}>On The Go</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label outfit-500">Description <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            <textarea onChange={(e) => handleInput(e)} name="description" value={inputValues?.description} className="form-control outfit-500" id="" rows={3} defaultValue={""} />
                        </div>
                    </div>


                </div>
            </Modal>
      
            <div id="preloader">
                <div id="status">
                    <ScaleLoader color="#fff" />
                </div>
            </div>

            <div>
                <div className="row g-3 justify-content-center">
                    <div className="col-12 col-md-2 shadow stat-card-dash">
                        <div className="row">
                            <div className="col-4">
                                <div className="stat-radial active-blue-bg">
                                    <MdBarChart
                                        style={{ fontSize: "25px", color: "#fff" }}
                                    />
                                </div>
                            </div>
                            <div className="col-8 ">
                                <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Properties</p>
                                <h3 style={{ lineHeight: "5px" }}>{cardDetails?.length}</h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-2 shadow stat-card-dash">
                        <div className="row">
                            <div className="col-4">
                                <div className="stat-radial active-green-bg" style={{ backgroundColor: "#f4f7fe" }}>
                                    <MdGroups
                                        style={{ fontSize: "25px", color: "#fff" }}
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <p className="outfit-500" style={{ color: "#a3aed0" }}>Active Properties</p>
                                <h3 style={{ lineHeight: "5px" }}>-</h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-2 shadow stat-card-dash">
                        <div className="row">
                            <div className="col-4">
                                <div className="stat-radial " style={{ backgroundColor: "#f4f7fe" }}>
                                    <MdGroupOff
                                        style={{ fontSize: "25px", color: "#422AFB" }}
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <p className="outfit-500" style={{ color: "#a3aed0" }}>Inactive Properties</p>
                                <h3 style={{ lineHeight: "5px" }}>-</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 shadow stat-card-dash">
                        <div className="row">
                            <div className="col-4">
                                <div className="stat-radial active-green-bg" style={{ backgroundColor: "#f4f7fe" }}>
                                    < MdGroup
                                        style={{ fontSize: "25px", color: "#fff" }}
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <p className="outfit-500" style={{ color: "#a3aed0" }}>Newly Added</p>
                                <h3 style={{ lineHeight: "5px" }}>-</h3>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-md-2 shadow stat-card-dash">
                        <div className="row">
                            <div className="col-4">
                                <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                                    <MdAttachMoney
                                        style={{ fontSize: "25px", color: "#422AFB" }}
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <p className="outfit-500" style={{ color: "#a3aed0" }}>Weekly Revenue</p>
                                <h3 style={{ lineHeight: "5px" }}></h3>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row mt-4 justify-content-center">
                    {visibilityIndicator == 1 &&
                        <div className="row">
                            {!isMobile ?
                                <div className="col-12 mt-4">
                                    <Card>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <p className="outfit-600" style={{ fontSize: "18px" }}>
                                                    Property Management
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">

                                                <button onClick={() => showModal()} className="btn btn-primary btn-sm outfit-500 float-end" style={{ marginRight: "5px" }}>
                                                    Add Property <MdAddCircleOutline />
                                                </button>
                                            </div>

                                        </div>
                                        <Table columns={columns} dataSource={data} />
                                    </Card>
                                </div> :
                                <p>Tables are not shown on mobile devices</p>
                            }
                        </div>
                    }

                    {visibilityIndicator == 2 &&

                        <div className="row justify-content-center">
                            <div className="col-12 col-md-5 mt-3">
                                {/* <button> */}
                                <i class="fa-solid fa-circle-arrow-left" onClick={() => toggleVisibility("", 1)} style={{ color: "#0d6efd", fontSize: "27px" }}></i>
                                {/* </button> */}
                                {/* <p className="outfit-600" style={{ fontSize: "22px" }}>
                                            House Cleaning
                                        </p> */}
                            </div>
                            <div className="col-12 col-md-6 text-right">
                                <button onClick={() => showModal()} className="btn btn-primary outfit-500 float-end">
                                    New Price Plan <MdAddCircleOutline />
                                </button>
                            </div>
                            {/* <div className="col-md-3 col-12 shadow mt-1" style={{ padding: "23px", borderRadius: "25px", marginRight: "10px" }}>
                                        <img className="bann-img" src={baseContentURL + selectedServiceCategory?.imageUrl} alt="..." />
                                        <p className="outfit-600 text-center" style={{ fontSize: "21px", color: "#1b2559" }}>{selectedServiceCategory?.name}</p>
                                        <div>
                                            <Badge.Ribbon text={selectedServiceCategory?.badgeText} color="green">
                                                <Card className="outfit-500" title="Caption" size="small">
                                                    {selectedServiceCategory?.subCaption}
                                                </Card>
                                            </Badge.Ribbon>
                                        </div>
                                        <div>
                                            <Badge.Ribbon text="" color="green">
                                                <Card className="outfit-500" title="Description" size="small">
                                                    {selectedServiceCategory?.description?.length > 200 ? selectedServiceCategory?.description?.substring(0, 200) + "..." : selectedServiceCategory?.description}
                                                </Card>
                                            </Badge.Ribbon>

                                        </div>
                                        <button className="btn btn-warning outfit-400 btn-sm mt-2"><i className="fa fa-edit" /> Edit</button>
                                    </div> */}
                            {/* <div className="col-md-4 col-12 shadow mt-1" style={{ padding: "23px",  marginRight: "10px", maxHeight: "39.5em",  }}>
                                        
                                    <div style={{ overflow: "scroll", maxHeight: "34em", }}>
                                        <p className="outfit-600" style={{ fontSize: "21px", color: "#3db07e" }}><i style={{ fontSize: "18px" }} class="fa-solid fa-location-dot" /> Service Locations <span style={{ fontSize: "12px", color: "grey" }}>(States/Lgas)</span></p>
                                        <div className="mt-2"
                                        >
                                            <TreeCompo />
                                            
                                        </div>
                                        </div>
                                    <button className="btn btn-primary btn-sm mt-2 outfit-500 float-end"><i className="fa fa-save" /> Save selection(s)</button>
                                    </div> */}
                            <div className="col-md-11 col-12 mt-1">
                                <div className="pr-body">
                                    <header className="pr-header">
                                        <h1>Our Pricing</h1>
                                        <div className="pr-toggle">
                                            <label>Annually </label>
                                            <div className="pr-toggle-btn">
                                                <input type="checkbox" className="checkbox" id="checkbox" />
                                                <label className="sub" id="sub" htmlFor="checkbox">
                                                    <div className="circle" />
                                                </label>
                                            </div>
                                            <label> Monthly</label>
                                        </div>
                                    </header>
                                    <div className="pr-cards">
                                        <div className="pr-card shadow">
                                            <ul className="pr-ul">
                                                <li className="pack">Basic</li>
                                                <li id="basic" className="price bottom-bar">$199.99</li>
                                                <li className="bottom-bar">500 GB Storage</li>
                                                <li className="bottom-bar">2 Users Allowed</li>
                                                <li className="bottom-bar">Send up to 3 GB</li>
                                                <li><button className="btn">Learn More</button></li>
                                            </ul>
                                        </div>
                                        <div className="pr-card active">
                                            <ul className="pr-ul">
                                                <li className="pack">Professional</li>
                                                <li id="professional" className="price bottom-bar">$249.99</li>
                                                <li className="bottom-bar">1 TB Storage</li>
                                                <li className="bottom-bar">5 Users Allowed</li>
                                                <li className="bottom-bar">Send up to 10 GB</li>
                                                <li><button className="btn active-btn">Learn More</button></li>
                                            </ul>
                                        </div>
                                        <div className="pr-card shadow">
                                            <ul className="pr-ul">
                                                <li className="pack">Master</li>
                                                <li id="master" className="price bottom-bar">$399.99</li>
                                                <li className="bottom-bar">2 TB Storage</li>
                                                <li className="bottom-bar">10 Users Allowed</li>
                                                <li className="bottom-bar">Send up to 20 GB</li>
                                                <li><button className="pr-btn">Learn More</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row">
                                            <div className="col-12 mt-1">
                                                <Card>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <p className="outfit-600" style={{ fontSize: "18px" }}>
                                                                Price Plans
                                                            </p>
                                                        </div>

                                                    </div>
                                                    {isMobile ? <h5>Tables are not shown on mobile devices</h5> : <Table columns={serviceplanscolumns} dataSource={data} />}
                                                </Card>
                                            </div>
                                        </div> */}

                            </div>

                        </div>
                    }

                </div>
            </div>

        </>
    );
};

export default withLayout(Sidenav)(PropertyManagement);
