import React, { useEffect, useState } from "react";

import withLayout from "../../layouts/withLayout";
import Sidenav from "../../layouts/adminLayout/sidenav";
import { Card, Table } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlinePersonRemoveAlt1,
  MdOutlinePersonOff,
  MdGroups,
  MdGroupOff,
  MdGroup,
} from "react-icons/md";
import Endpoint from "../../utils/endpoint";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Spinner from "../../components/spinner";
import { SphereSpinner } from "react-spinners-kit";
import { ScaleLoader } from "react-spinners";
import $ from "jquery"
import { enquireScreen } from 'enquire-js';


const EventManagement = () => {
  const [tableContent, setTableContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [cardDetails, setCardDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  const data = tableContent?.map((item, index) => {
    return {
      eventTitle:
        item?.eventTitle?.length > 30
          ? item?.eventTitle.substring(0, 30) + "..."
          : item?.eventTitle,
      categoryName: item?.categoryName,
      address:
        item?.address?.length > 40
          ? item?.address.substring(0, 40) + "..."
          : item?.address,
      hostName: item?.hostName,
      eventDate: item?.eventDate,
      icon: (
        <div>
          <BsFillInfoCircleFill
            style={{ height: "32px", width: "32px" }}
            color="#422AFB"
          />
        </div>
      ),
    };
  });

  const columns = [
    {
      title: "EVENT TITLE",
      dataIndex: "eventTitle",
      key: "eventTitle",
    },
    {
      title: "EVENT CATEGORY",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "EVENT ADDRESS",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "HOST NAME",
      dataIndex: "hostName",
      key: "hostName",
      // render: () => <a href="#">Edit</a>,
    },
    {
      title: "EVENT DATE",
      dataIndex: "eventDate",
      key: "eventDate",
      // render: () => <a href="#">Edit</a>,
    },
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      // render: () => <a href="#">Edit</a>,
    },
  ];

  const getAll = async (pageNumber, pageSz, searchQuery) => {
    try {
      setCurrentPage(pageNumber);
      const res = await Endpoint.getEventList(pageNumber, pageSz, searchQuery); // Adjust parameters as needed
      console.log(res.data, "res");
      setCardDetails(res.data);
      setTableContent(res.data.events);
      $('#preloader').delay(450).fadeOut('slow');

    } catch (err) {
      console.log(err, "Err");
    }
  };

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(b)
    });
    getAll(currentPage, pageSize, currentSearchQuery);
  }, []);

  return (
    <>
      <div id="preloader">
        <div id="status">
          <ScaleLoader color="#fff"/>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
            <div className="row g-3 justify-content-center">
              <div className="col-12 col-md-2 shadow stat-card-dash">
                <div className="row">
                  <div className="col-4">
                    <div className="stat-radial active-blue-bg">
                      <MdBarChart
                        style={{ fontSize: "25px", color: "#fff" }}
                      />
                    </div>
                  </div>
                  <div className="col-8 ">
                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Events</p>
                    <h3 style={{ lineHeight: "5px" }}>-</h3>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-2 shadow stat-card-dash">
                <div className="row">
                  <div className="col-4">
                    <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                      <MdGroups
                        style={{ fontSize: "25px", color: "#422AFB" }}
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Users</p>
                    <h3 style={{ lineHeight: "5px" }}>678</h3>
                  </div>
                </div>
              </div>


              <div className="col-12 col-md-2 shadow stat-card-dash">
                <div className="row">
                  <div className="col-4">
                    <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                      <MdGroupOff
                        style={{ fontSize: "25px", color: "#422AFB" }}
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Suspended Users</p>
                    <h3 style={{ lineHeight: "5px" }}>678</h3>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-2 shadow stat-card-dash">
                <div className="row">
                  <div className="col-4">
                    <div className="stat-radial active-green-bg">
                      <MdGroup
                        style={{ fontSize: "25px", color: "#fff" }}
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Active Users</p>
                    <h3 style={{ lineHeight: "5px" }}>678</h3>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-2 shadow stat-card-dash">
                <div className="row">
                  <div className="col-4">
                    <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                      <MdAttachMoney
                        style={{ fontSize: "25px", color: "#422AFB" }}
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Weekly Revenue</p>
                    <h3 style={{ lineHeight: "5px" }}>678</h3>
                  </div>
                </div>
              </div>

            </div>
          <div className="row">
            <div className="col-12 mt-4">
              <Card>
                  <p className="outfit-600" style={{ fontSize: "18px" }}>
                  Event Management
                </p>
                  {isMobile ? <h5>Tables are not shown on mobile devices</h5> : <Table columns={columns} dataSource={data} />}
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withLayout(Sidenav)(EventManagement);
