import React from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import xplurlogo from "../assets/images/xplurlogo.png";

const override = css`
  display: block;
  margin: 0 auto;
`;

const Spinner = ({ loading }) => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <img
        src={xplurlogo}
        alt="logo"
        style={{ width: "100px", height: "100px" }}
      />
      <ClipLoader
        color={"#123abc"}
        loading={loading}
        css={override}
        size={50}
      />
    </div>
  );
};

export default Spinner;
