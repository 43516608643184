import React from "react";
import withLayout from "../../layouts/withLayout";
import Sidenav from "../../layouts/adminLayout/sidenav";
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlinePersonRemoveAlt1,
  MdOutlinePersonOff,
  MdGroups,
  MdGroupOff,
  MdGroup,
} from "react-icons/md";



const AdminIndex = () => {
  return (
    <div>
     <div className="row g-3 justify-content-center">
        <div className="col-12 col-md-2 shadow stat-card-dash">
         <div className="row">
          <div className="col-4">
              <div className="stat-radial active-blue-bg">
                <MdBarChart
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              </div>
          </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color:"#a3aed0"}}>Reports</p>
              <h3 style={{lineHeight:"5px"}}>0</h3>
            </div>
         </div>
      </div>

        <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                <MdGroups
                  style={{ fontSize: "25px", color: "#422AFB" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Users</p>
              <h3 style={{ lineHeight: "5px" }}>18</h3>
            </div>
          </div>
        </div>


        <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                <MdGroupOff
                  style={{ fontSize: "25px", color: "#422AFB" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Suspended Users</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial active-green-bg">
                <MdGroup
                  style={{ fontSize: "25px", color: "#fff" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Active Users</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>
      
        <div className="col-12 col-md-2 shadow stat-card-dash">
          <div className="row">
            <div className="col-4">
              <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                <MdAttachMoney
                  style={{ fontSize: "25px", color: "#422AFB" }}
                />
              </div>
            </div>
            <div className="col-8">
              <p className="outfit-500" style={{ color: "#a3aed0" }}>Weekly Revenue</p>
              <h3 style={{ lineHeight: "5px" }}>-</h3>
            </div>
          </div>
        </div>
        
     </div>
     

     
    </div>
  );
};

export default withLayout(Sidenav)(AdminIndex);
