import React, { useEffect } from 'react';
import withLayout from '../../../layouts/withLayout';
import FrontLayout from '../../../layouts/frontLayout/layout';
import MainBanner from './main-banner';
import SectionTwo from './section-two';
import logoLloydant from "../../../assets/images/logoFresh.png"
import $ from "jquery"


function Index() {
    
    useEffect(() => {
        $(function () {
            setTimeout(() => {
                $('#preloader').delay(450).fadeOut('slow');
            }, 3000);
        });
    }, [])
    return (
        <div className="App">
            {/* <div id="preloader">
                <div id="status">
                    <img alt='loader' src={logoLloydant} className="pt-5 lloyd-logo" />
                </div>
            </div> */}
            <MainBanner />
            {/* <SectionTwo/> */}
        </div>
    );
}

export default withLayout(FrontLayout)(Index);
